const buildConsulateUrl = (slug = null) => {
  const base = `/consulado`;
  if (!slug) return base;
  return `${base}/${slug}/`;
}

const buildPostSlug = (slug) => {
  return `/${slug}/`;
}

const buildNewsUrl = (hasPage = false, page = 0) => {
  const base = `/noticias`;
  if (hasPage) return `${base}/pagina${page > 0 ? `/${page}/` : ''}`;
  return `${base}/`;
}

const buildCategoryUrl = (slug = null, hasPage = false, page = 0) => {
  const base = `/categoria`;
  if (!slug && !hasPage) return base;
  if (slug && hasPage) return `${base}/${slug}/pagina${page > 0 ? `/${page}/` : ''}`;
  return `${base}/${slug}/`;
}

module.exports = {
  buildConsulateUrl,
  buildPostSlug,
  buildNewsUrl,
  buildCategoryUrl
}