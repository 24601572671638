import React from "react"
import { Link } from "gatsby"
import BackgroundImage from "gatsby-background-image"

/**
 * Hero component
 * This component is used to show the Hero component
 * All props values like image, type or title should be
 * sent via Parent and graphql queries should not be
 * executed inside this component
 *
 * available types: `simple` | `main`
 * all props are optional except `type` and `background`
 *
 * @param {Object} props
 */
const Hero = ({ type, background, tagline, title, description, cta }) => {
  return (
    <BackgroundImage
      Tag="section"
      className={`hero ${type}`}
      fluid={background}
    >
      <div className="container">
        {tagline && <span className="hero-tagline">{tagline}</span>}
        {title && <h1 className="hero-title">{title}</h1>}
        {description && <p className="hero-description">{description}</p>}
        {cta && (
          <Link className="hero-cta" to={cta.link}>
            {cta.title}
          </Link>
        )}
      </div>
    </BackgroundImage>
  )
}

export default Hero
