/**
 * Build the category slug
 *
 * @param {String} title
 */
export const createCategorySlug = title =>
  title
    .replace(/[^A-Z0-9]+/gi, "-")
    .trim()
    .toLowerCase()

/**
 * Check if an array of posts belongs to some category Id
 * @param {string} category
 * @param {Array} posts
 */
export const hasPosts = (category, posts = []) => {
  return posts.filter(post => {
    return post.categories.find(cat => cat.id === category)
  }).length > 0
    ? true
    : false
}

/**
 * encode Object to query params
 *
 * @param {Object} data
 */
export const encode = data => {
  return Object.keys(data)
    .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`)
    .join("&")
}

/**
 * Convert an graphql edge to node
 * @param {Object} edge 
 */
export const mapEdgesToNodes = edge => {
  return edge.node;
}

/**
 * Convert an graphql edge to node
 * @param {Object} edge 
 */
export const mapFrontmattersToFront = node => {
  const { frontmatter, ...rest } = node;
  return {
    ...(frontmatter || {}),
    ...rest,
  };
}