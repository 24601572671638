import React from "react"
import OptimizedImage from "gatsby-image/withIEPolyfill"
import {
  FaTwitter,
  FaGooglePlusG,
  FaFacebook,
  FaInstagram,
  FaYoutube,
} from "react-icons/fa"
import { Link } from "gatsby"
import { buildNewsUrl } from "../../utils/urls"

/**
 * Footer Component
 * This Component is used to generate the Footer of the site
 * The data should be sent via Parent component like layout, the required props are
 * - siteTitle
 * - logo
 *
 * @param {Object} props
 */
const Footer = ({ siteTitle, logo }) => (
  <footer className="site-footer">
    <div className="container">
      <div className="row">
        <div className="col-xl-4 col-md-6">
          <div className="site-footer__info">
            <Link className="info__logo" to="/" title={siteTitle}>
              <OptimizedImage
                className="logo__img"
                fluid={logo}
                alt="Logo"
                fadeIn={true}
              />
            </Link>
            <p>
            Nuestro objetivo primordial es brindarle asesoría jurídica<br />
            de las leyes guatemaltecas  a todos los chapines que residen en Estados Unidos de América.
            </p>
            <p>
            darles una solución real y efectiva  en el menor tiempo posible a todos sus asuntos legales
            pendientes de resolver en Guatemala.
            </p>
            <br/>
            <p>Guatemalan Law and Notary Consulting</p> 
          </div>
        </div>
        <div className="col-xl-2 col-md-6">
          <h3>Servicios legales</h3>
          <ul className="site-footer__links">
            <li>
              <Link to="/servicios/mandatos/">Mandatos</Link>
            </li>
            <li>
              <Link to="/servicios/compra-venta-de-propiedades/">Propiedades</Link>
            </li>
            <li>
              <Link to="/servicios/testamentarios-o-intestados/">Testamentarios o Intestados</Link>
            </li>
            <li>
              <Link to="/servicios/divorcios">Divorcios</Link>
            </li>
            <li>
              <Link to="/servicios/testamentos">Testamentos</Link>
            </li>
            <li>
              <Link to="/servicios/reconocimiento-de-hijos/">Reconocimiento hijos</Link>
            </li>
            <li>
              <Link to="/servicios/cambio-de-nombre/">Cambio de nombre</Link>
            </li>
          </ul>
        </div>
        <div className="col-xl-3 col-md-6">
          <h3>Accesos rápidos</h3>
          <ul className="site-footer__links">
            <li>
              <Link to="/">Inicio</Link>
            </li>
            <li>
              <Link to="/acerca-de/">Acerca de</Link>
            </li>
            <li>
              <Link to={buildNewsUrl()}>Noticias</Link>
            </li>
            <li>
              <Link to="/servicios/">Servicios</Link>
            </li>
            <li>
              <Link to="/contacto/">Contacto</Link>
            </li>            
          </ul>
        </div>
        <div className="col-xl-3 col-md-6">
          <h3>Oficina Central USA</h3>
          <p>
          88 BELVEDERE ST. SUIT 205 <br /> SAN RAFAEL CALIFORNIA 94901
          </p>
          <br />
          <p>
            <span>Teléfono: </span>{" "}
            <a href="tel:415-259-6040">415 259-6040 / </a>
            <a href="tel:415-532-9880">415 532-9880</a>
          </p>
          <br />
          <p>
            Lunes a Viernes : 09:00 am - 06:00 pm <br /> Sábado : 09:00 am - 01:00 pm
          </p>

          <br />
          <h3>Oficina Guatemala</h3>
          <p>
            <span>Teléfono: </span>{" "}
            <a href="tel:+50278880180">+502 7888-0180</a>
          </p>
        </div>
      </div>
    </div>

    <div className="site-footer__bottom">
      <div className="container">
        <p className="site-footer__copy">
          © 2020 Lic. Erick Claveria. All Rights Reserved.{" "}
        </p>

        {/* <div className="site-footer__social">
          <a href={`/`}>
            <FaTwitter className="social__twitter" />
          </a>
          <a href={`/`}>
            <FaGooglePlusG className="social__google" />
          </a>
          <a href={`/`}>
            <FaFacebook className="social__facebook" />
          </a>
          <a href={`/`}>
            <FaInstagram className="social__instagram" />
          </a>
          <a href={`/`}>
            <FaYoutube className="social__youtube" />
          </a>
        </div> */}
      </div>

      <div className="container">
        <p className="site-footer__copy-v2">
          La información contenida en esta página web es información general y bajo ninguna interpretación debe ser considerada como asesoría legal ningún contenido de la presente página debe ser considerado como una asesoría o consejo legal para ningún caso o persona en particular dentro o fuera de los Estados Unidos de América. 

          Esta información no está destinada a crear una relación contractual con
          ninguna persona, la sola visualización de esta página no constituye una relación contractual entre abogado cliente. Nosotros no brindamos asesoría legal para ningún caso que deba tramitarse dentro y conforme las leyes de Estados Unidos de América, tampoco referimos ni practicamos ningún área legal en los Estados Unidos de América. 
          
          El servicio que prestamos se limita únicamente elaborar
          Mandatos o Documentos Legales que solo deberán y podrán ser utilizados dentro del territorio de la Republica de Guatemala, fundamentados al tenor de lo que establece La Constitución Política de la Republica de Guatemala, libertad de acción, el artículo 43 de la Ley del Organismo Judicial, Leyes Civiles de la Republica de Guatemala, Leyes de Derecho Internacional, Tratados y Convenios internacionales.
        </p>
      </div>
    </div>
  </footer>
)

export default Footer
