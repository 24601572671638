import React from "react"
import Topbar from "./topbar"
import Navbar from "./Navbar"

/**
 * Header Component
 * This component is used to generate the Header of the site
 * - The header is the section that contains the Topbar and Navbar
 * - The props should be sent via parent Component like Layout Component
 * - The required props are
 *   - isSecondary
 *   - logo
 *   - siteTitle
 *   - email
 *   - address
 *   - phone
 *
 * @param {Object} props
 */
const Header = ({ isSecondary, logo, siteTitle, email, address, phone }) => (
  <div className={`site-header ${isSecondary ? "is-secondary" : ""}`}>
    <Topbar
      isSecondary={isSecondary}
      phone={phone}
      email={email}
      address={address}
    />
    <Navbar
      isSecondary={isSecondary}
      logo={logo}
      siteTitle={siteTitle}
      phone={phone}
    />
  </div>
)

export default Header
