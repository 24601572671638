import React, { useState, useEffect } from "react"
import OptimizedImage from "gatsby-image/withIEPolyfill"
import { Link, useStaticQuery, graphql } from "gatsby"
import { FaPhoneAlt, FaLongArrowAltRight } from "react-icons/fa"
import { mapEdgesToNodes, mapFrontmattersToFront } from "../../utils"
import { buildNewsUrl, buildConsulateUrl } from "../../utils/urls"

/**
 * Navbar Component
 * This component contains the main site navigation
 * - component params should be sent via parent like Header
 * - required props are
 *   - isSecondary
 *   - logo
 *   - siteTitle
 *   - phone
 *
 * @param {Object} props
 */
const Navbar = ({ isSecondary, logo, siteTitle, phone }) => {
  const [isOpen, toggleNavbar] = useState(false)
  const [isFixed, toggleFixed] = useState(false)
  const [activeDropdown, toggleActiveDropdown] = useState(null);
  let { consulates } = useStaticQuery(graphql`
    {
      consulates: allMdx(
        filter: { fileAbsolutePath: { regex: "/(consulates)/.*.md(x)$/" } }
      ) {
        edges {
          node {
            frontmatter {
              slug
              name
            }
          }
        }
      }
    }
  `)

  consulates = ((consulates || {}).edges || []).map(mapEdgesToNodes).map(mapFrontmattersToFront);

  /**
   * Listener function
   * this function is listening for scroll events
   * will update navbar styles based on page scroll status
   */
  const listener = () => {
    if (
      document.body.scrollTop > 170 ||
      document.documentElement.scrollTop > 170
    ) {
      toggleFixed(true)
    } else {
      toggleFixed(false)
    }
  }

  useEffect(() => {
    window.addEventListener("scroll", listener)
    return () => {
      window.removeEventListener("scroll", listener)
    }
  })

  /**
   * Toggle navbar status
   * This component will change navigation status open/closed
   * used to open and close the navbar in mobile devices
   */
  const toggle = () => toggleNavbar(!isOpen)

  /**
   * Toggle consulates dropdown
   * 
   * 
   */
  const toggleConsulates = () => toggleActiveDropdown(activeDropdown === 'consulates' ? null : 'consulates')

  return (
    <header
      className={`site-header__nav ${isOpen ? "open" : ""} ${
        isFixed ? "fixed scroll-down" : ""
      } ${isSecondary ? "secondary" : ""}`}
    >
      <Link className="nav__logo" to="/" title={siteTitle}>
        <OptimizedImage
          className="logo__img"
          fluid={logo.sharp.fluid}
          alt="Logo"
          fadeIn={true}
          objectFit="contain"
          objectPosition="50% 50%"
        />
      </Link>

      <button className="nav__toggler" onClick={toggle}>
        Menu
      </button>
      
      <nav className="nav__items">
        <Link className="nav-items__item" to="/">
          Inicio
        </Link> 
        <Link className="nav-items__item" to="/acerca-de/">
          Acerca de
        </Link>
        {/* <Link className="nav-items__item" to={buildNewsUrl()}>
          Noticias
        </Link> */}
        <Link className="nav-items__item" to="/servicios/">
          Servicios
        </Link>        
        <div className="nav-items__item w-dropdown">
          <button onClick={toggleConsulates}>Consulados</button>
          <div className={`nav-item__dropdown ${activeDropdown === 'consulates' ? 'active' : ''}`}>
            <ul>
              {consulates.map(consulate => <Link key={consulate.slug} className="dropdown__item" to={buildConsulateUrl(consulate.slug)}>{consulate.name}</Link>)}
            </ul>
          </div>
        </div>               
        <Link className="nav-items__item" to="/contacto/">
          Contacto
        </Link>
      </nav>

      <a className="nav__phone" href={`tel:${phone.replace(/[^\w\s]/gi, "")}`}>
        <FaPhoneAlt />
        <small>Telefono:&nbsp;</small>
        <span>{phone}</span>
      </a>

      <a className="nav__cta" href={`/contacto/`}>
        <span>Contactenos</span>
        <FaLongArrowAltRight />
      </a>
    </header>
  )
}

export default Navbar
