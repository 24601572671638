import React from "react"
import {
  FaEnvelopeOpen,
  FaMapMarkerAlt,
  // FaTwitter,
  // FaGooglePlusG,
  FaFacebook,
  // FaInstagram,
  // FaYoutube,
  FaPhoneAlt,
  FaClock,
  FaLongArrowAltRight,
} from "react-icons/fa"

/**
 * Topbar component
 * This component is used to show the topbar section in the header
 * - Props should be sent via parent component like Header
 * - required props are
 *   - isSecondary
 *   - phone
 *   - email
 *   - address
 *
 * @param {Object} props
 */
const Topbar = ({ isSecondary, phone, email, address }) => {
  return (
    <div className={`${isSecondary ? "secondary" : ""} site-header__topbar`}>
      {!isSecondary && (
        <div className="topbar-left">
          <p>
            Bienvenidos a Lic. Erick Claveria, Abogado de Guatemala en San
            Francisco, California, Estados Unidos.
          </p>
        </div>
      )}

      {isSecondary && (
        <div className="topbar-left">
          <a href={`tel:${phone.replace(/[^\w\s]/gi, "")}`}>
            <FaPhoneAlt />
            Haga su cita:&nbsp; <span>{phone}</span>
          </a>
          <p
            className="nav__phone"
            href={`tel:${phone.replace(/[^\w\s]/gi, "")}`}
          >
            <FaClock />
            <span>{`Lunes a Viernes 9am - 6pm`}</span>
          </p>
          <a href={`mailto:${email}`}>
            <FaEnvelopeOpen />
            <span>{email}</span>
          </a>
        </div>
      )}

      {!isSecondary && (
        <div className="topbar-right">
          <a href={`mailto:${email}`}>
            <FaEnvelopeOpen />
            <span>{email}</span>
          </a>
          <a href={`/`}>
            <FaMapMarkerAlt />
            <span>{address}</span>
          </a>

          <div className="topbar-right__social">
            {/*<a href={`/`}><FaTwitter className="social__twitter" /></a>
            <a href={`/`}><FaGooglePlusG className="social__google" /></a>*/}
            {/* <a href={`/`}>
              <FaFacebook className="social__facebook" />
            </a> */}
            {/*<a href={`/`}><FaInstagram className="social__instagram" /></a>
            <a href={`/`}><FaYoutube className="social__youtube" /></a>*/}
          </div>
        </div>
      )}

      {isSecondary && (
        <div className="topbar-right">
          <a className="btn btn-red" href={`/contacto/`}>
            <span>CONTACTENOS</span>&nbsp;&nbsp;
            <FaLongArrowAltRight />
          </a>
        </div>
      )}
    </div>
  )
}

export default Topbar
