/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import Header from "./common/header"
import Footer from "./common/footer"
import { FaAngleUp } from "react-icons/fa"

/**
 * Layout component
 * This component is used as the main layout component
 * this component will be used in all the templates/pages
 *
 * @param {Object} props
 */
const Layout = ({ isSecondary = false, children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
          email
          address
          phone
        }
      }
      logo: file(relativePath: { eq: "logo.png" }) {
        sharp: childImageSharp {
          fluid(maxWidth: 240) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)
  const [showBackButton, toggleBackButton] = useState(false)

  /**
   * Listener function
   * This function will update the back button status
   * based on scroll handler
   *
   * @param {Object} event
   */
  const listener = e => {
    if (
      document.body.scrollTop > 20 ||
      document.documentElement.scrollTop > 20
    ) {
      toggleBackButton(true)
    } else {
      toggleBackButton(false)
    }
  }

  /**
   * Scroll to Top function
   * This function will fire an animated scroll
   * from the current position to the top of the page
   *
   */
  const scrollToTop = () => {
    if (document.scrollingElement.scrollTop === 0) return

    const cosParameter = document.scrollingElement.scrollTop / 2
    let scrollCount = 0,
      oldTimestamp = null

    const step = newTimestamp => {
      if (oldTimestamp !== null) {
        scrollCount += (Math.PI * (newTimestamp - oldTimestamp)) / 500
        if (scrollCount >= Math.PI)
          return (document.scrollingElement.scrollTop = 0)
        document.scrollingElement.scrollTop =
          cosParameter + cosParameter * Math.cos(scrollCount)
      }
      oldTimestamp = newTimestamp
      window.requestAnimationFrame(step)
    }
    window.requestAnimationFrame(step)
  }

  useEffect(() => {
    window.addEventListener("scroll", listener)
    return () => {
      window.removeEventListener("scroll", listener)
    }
  })

  return (
    <>
      <Header
        isSecondary={isSecondary}
        siteTitle={data.site.siteMetadata.title}
        email={data.site.siteMetadata.email}
        address={data.site.siteMetadata.address}
        phone={data.site.siteMetadata.phone}
        logo={data.logo}
      />
      <div>
        <main>{children}</main>
      </div>

      {showBackButton && (
        <button onClick={scrollToTop} className="back_to_top">
          <FaAngleUp />
        </button>
      )}
      <Footer
        siteTitle={data.site.siteMetadata.title}
        logo={data.logo.sharp.fluid}
      />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
